import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
<path d="M2510 3634 c0 -5 7 -18 15 -28 8 -11 15 -16 15 -10 0 5 -7 18 -15 28
-8 11 -15 16 -15 10z"/>
<path d="M2428 3503 c-21 -2 -38 -6 -38 -8 0 -2 17 -32 39 -67 21 -35 74 -126
119 -203 44 -77 150 -259 235 -405 84 -146 161 -280 171 -297 l17 -33 104 0
c58 0 105 3 105 6 0 7 -23 30 -32 32 -35 8 -108 62 -125 92 -63 113 -77 135
-82 140 -3 3 -19 30 -35 60 -16 30 -38 66 -48 79 -10 13 -18 28 -18 34 0 6
-11 25 -25 43 -14 18 -25 36 -25 39 0 4 -16 34 -36 68 -20 34 -48 82 -62 107
-13 25 -30 52 -36 60 -7 8 -27 44 -46 80 -18 36 -36 67 -40 70 -4 3 -17 28
-31 57 -26 56 -23 55 -111 46z"/>
<path d="M2610 3451 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M2640 3414 c0 -6 7 -19 16 -30 14 -18 14 -18 3 6 -14 31 -19 36 -19
24z"/>
<path d="M2670 3353 c0 -6 8 -17 18 -24 16 -13 16 -13 2 6 -8 11 -16 22 -17
24 -2 2 -3 0 -3 -6z"/>
<path d="M2440 3240 c-15 -36 -36 -53 -28 -23 3 12 0 20 -7 20 -10 0 -11 -7
-4 -24 5 -14 5 -31 0 -41 -5 -9 -17 -34 -27 -54 -12 -25 -21 -33 -26 -25 -6 9
-8 8 -8 -3 0 -13 -1 -13 -10 0 -8 12 -10 12 -10 1 0 -8 -8 -4 -20 10 -18 23
-19 23 -21 4 -1 -11 -1 -24 0 -29 1 -4 -6 -14 -15 -20 -12 -9 -14 -19 -8 -37
4 -13 7 -31 6 -39 -3 -13 -2 -13 7 0 5 8 13 11 17 7 4 -3 1 -13 -6 -22 -7 -9
-10 -19 -6 -22 8 -8 26 16 26 35 0 7 -7 15 -15 18 -11 5 -12 11 -5 24 6 10 10
28 10 39 0 15 6 20 20 18 31 -5 34 -14 11 -46 -13 -18 -17 -32 -11 -36 5 -3
10 -1 10 5 0 6 15 34 33 63 57 90 119 207 109 207 -5 0 -15 -14 -22 -30z"/>
<path d="M2360 3210 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M2750 3210 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2350 3181 c0 -5 -10 -11 -22 -13 -12 -2 -23 -9 -24 -16 -1 -7 0 -8
3 -2 11 22 30 10 27 -17 -3 -24 -2 -24 6 5 6 17 17 32 25 32 8 0 15 5 15 10 0
6 -7 10 -15 10 -8 0 -15 -4 -15 -9z"/>
<path d="M2355 3140 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2841 3069 c-1 -8 6 -24 15 -35 15 -19 15 -19 6 1 -5 11 -12 27 -15
35 -5 13 -6 13 -6 -1z"/>
<path d="M2227 3014 c-3 -5 1 -15 9 -22 16 -14 18 -5 5 17 -5 7 -11 9 -14 5z"/>
<path d="M2202 2970 c-8 -13 -8 -20 -2 -20 6 0 13 7 17 15 9 24 -1 27 -15 5z"/>
<path d="M2890 2970 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2246 2951 c-4 -7 -3 -18 4 -26 9 -11 6 -17 -17 -32 -33 -21 -44 -34
-37 -45 3 -4 -1 -8 -8 -8 -11 0 -11 -2 0 -9 11 -6 9 -15 -8 -42 -19 -30 -20
-24 -3 19 3 8 0 10 -11 6 -10 -4 -16 -18 -16 -36 0 -28 -2 -30 -27 -23 -44 11
-45 11 -48 -3 -2 -7 -1 -8 2 -2 3 6 16 4 34 -6 25 -12 28 -18 18 -30 -6 -8 -7
-14 -1 -14 12 0 38 30 26 30 -5 0 -4 4 2 8 16 10 67 102 58 102 -5 0 3 11 18
25 14 13 29 33 33 44 8 25 -9 60 -19 42z"/>
<path d="M2180 2929 c0 -8 -9 -25 -20 -37 -24 -25 -25 -35 -5 -52 13 -10 15
-9 15 12 0 28 28 58 53 58 19 0 23 15 6 26 -6 3 -15 0 -20 -7 -8 -12 -11 -12
-19 1 -9 13 -10 13 -10 -1z"/>
<path d="M2435 2789 c-45 -80 -55 -103 -45 -112 10 -9 12 -8 7 6 -4 11 -1 17
8 17 9 0 12 6 9 15 -4 8 -1 15 5 15 6 0 11 8 11 19 0 10 7 24 15 31 18 15 20
-1 3 -18 -9 -9 -9 -12 0 -12 7 0 12 5 12 12 0 20 13 -1 14 -24 3 -30 -20 -57
-43 -51 -24 6 -34 -30 -17 -61 12 -24 12 -24 30 16 9 22 24 47 32 55 8 9 14
25 13 37 0 11 0 26 1 31 3 10 6 125 4 125 -1 0 -28 -45 -59 -101z m43 36 c-6
-14 -13 -23 -15 -21 -5 5 15 46 22 46 3 0 -1 -11 -7 -25z m8 -41 c-4 -9 -9
-15 -11 -12 -3 3 -3 13 1 22 4 9 9 15 11 12 3 -3 3 -13 -1 -22z m-46 -129 c0
-8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z"/>
<path d="M2113 2823 c-16 -33 -17 -53 -3 -53 6 0 10 5 10 12 0 6 7 19 16 29 9
10 13 23 9 28 -10 17 -19 13 -32 -16z"/>
<path d="M3006 2777 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M2436 2724 c-4 -10 -1 -14 6 -12 15 5 23 28 10 28 -5 0 -13 -7 -16
-16z"/>
<path d="M2040 2698 c-17 -24 -19 -30 -7 -25 11 4 17 1 17 -8 0 -8 -6 -15 -13
-15 -15 0 -47 -31 -47 -46 0 -5 4 -4 8 2 4 5 34 27 67 48 33 21 50 35 38 31
-18 -5 -22 -3 -17 9 3 9 10 13 15 10 5 -3 9 2 9 10 0 9 -3 16 -8 16 -12 0 -33
-40 -27 -50 3 -6 -1 -7 -9 -4 -19 7 -20 16 -4 32 6 6 9 14 7 17 -3 2 -16 -9
-29 -27z"/>
<path d="M3066 2677 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M3086 2637 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M2174 2563 c192 -3 222 -6 239 -21 18 -16 19 -16 16 3 -4 19 -12 20
-239 20 l-235 1 219 -3z"/>
<path d="M3130 2561 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M1900 2535 c-8 -9 -9 -15 -2 -15 6 0 14 5 18 11 4 7 20 8 43 4 20 -4
131 -8 246 -9 l210 -2 -210 6 -210 5 205 5 205 5 -246 3 c-209 2 -248 0 -259
-13z"/>
<path d="M1910 2520 c-8 -4 -26 -13 -40 -18 -18 -8 59 -11 278 -11 235 -1 302
2 302 12 0 9 -59 11 -267 9 -182 -2 -263 1 -253 8 18 12 1 12 -20 0z"/>
<path d="M1391 2178 c-4 -13 -27 -75 -49 -138 -22 -63 -43 -123 -47 -132 -6
-15 -2 -18 22 -18 25 0 32 5 39 33 9 31 11 32 70 35 l60 3 9 -33 c8 -26 16
-34 38 -36 15 -2 27 1 27 6 0 6 -24 75 -54 153 -49 131 -56 144 -80 147 -20 3
-28 -2 -35 -20z m65 -142 c6 -24 4 -26 -28 -26 -19 0 -37 2 -40 5 -5 6 17 78
30 99 6 10 25 -30 38 -78z"/>
<path d="M1732 2048 l3 -153 68 -3 c66 -3 67 -2 67 22 0 21 -5 25 -37 28 l-38
3 -3 128 -3 127 -30 0 -30 0 3 -152z"/>
<path d="M2060 2045 l0 -155 30 0 30 0 0 73 0 72 50 -3 c48 -4 50 -3 50 22 0
25 -3 26 -50 26 l-50 0 0 35 0 35 55 0 c52 0 55 1 55 25 0 25 -1 25 -85 25
l-85 0 0 -155z"/>
<path d="M2443 2063 c-28 -76 -54 -146 -58 -155 -6 -15 -2 -18 22 -18 25 0 32
5 39 33 9 31 11 32 70 35 l61 3 9 -33 c8 -26 16 -34 37 -36 36 -4 35 8 -7 116
-19 48 -42 111 -52 140 -16 45 -22 52 -45 52 -24 0 -29 -9 -76 -137z m98 2 c9
-24 14 -46 11 -49 -9 -9 -72 -7 -72 2 0 20 33 103 38 97 4 -3 14 -26 23 -50z"/>
<path d="M2848 2194 c-16 -5 -18 -21 -18 -148 l0 -143 41 -7 c78 -12 161 26
186 86 31 72 6 151 -57 187 -39 22 -119 35 -152 25z m133 -67 c42 -26 46 -128
8 -163 -19 -17 -92 -26 -96 -11 -2 4 -3 50 -3 103 l0 96 36 -7 c20 -3 45 -12
55 -18z"/>
<path d="M3280 2045 l0 -155 30 0 30 0 0 155 0 155 -30 0 -30 0 0 -155z"/>
<path d="M3552 2048 l3 -153 65 0 c59 0 65 2 68 22 3 19 -2 22 -35 25 l-38 3
-3 128 -3 127 -30 0 -30 0 3 -152z"/>
<path d="M1250 1530 c0 -57 4 -80 13 -80 8 0 12 23 12 80 0 57 -4 80 -12 80
-9 0 -13 -23 -13 -80z"/>
<path d="M3150 1601 c0 -6 4 -11 8 -11 5 0 19 -30 32 -67 13 -37 25 -62 27
-57 5 16 -43 138 -56 142 -6 2 -11 -1 -11 -7z"/>
<path d="M3280 1608 c-7 -34 -2 -158 5 -158 6 0 10 33 10 80 0 44 -3 80 -7 80
-5 0 -8 -1 -8 -2z"/>
<path d="M1563 1530 c0 -41 2 -58 4 -37 2 20 2 54 0 75 -2 20 -4 3 -4 -38z"/>
<path d="M2881 1584 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1354 1536 c1 -30 4 -52 9 -49 9 6 9 75 -1 90 -5 7 -8 -11 -8 -41z"/>
<path d="M1841 1573 c-1 -16 11 -30 40 -49 31 -19 40 -30 36 -45 -3 -10 -1
-17 4 -13 21 13 7 45 -30 70 -22 14 -42 33 -44 42 -3 12 -5 10 -6 -5z"/>
<path d="M2115 1510 c0 -33 3 -60 7 -60 5 0 8 27 8 60 0 33 -3 60 -7 60 -5 0
-8 -27 -8 -60z"/>
<path d="M2233 1520 c11 -27 24 -50 28 -50 5 0 0 19 -11 42 -10 23 -16 46 -14
50 3 4 -1 8 -10 8 -12 0 -11 -9 7 -50z"/>
<path d="M2355 1510 c0 -33 3 -60 8 -60 4 0 7 27 7 60 0 33 -4 60 -8 60 -4 0
-8 -27 -7 -60z"/>
<path d="M3500 1510 c0 -41 4 -60 13 -60 8 0 11 17 9 60 -5 78 -22 78 -22 0z"/>
<path d="M1481 1513 c0 -28 2 -54 7 -57 4 -2 6 14 4 37 -5 68 -10 76 -11 20z"/>
<path d="M1701 1513 c-1 -28 3 -54 8 -57 5 -3 6 16 4 42 -7 69 -11 74 -12 15z"/>
<path d="M2040 1551 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M2490 1550 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2556 1544 c-13 -33 -6 -73 13 -84 30 -15 33 -12 10 11 -15 15 -19
28 -15 55 6 36 2 45 -8 18z"/>
<path d="M2621 1544 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2681 1548 c-1 -9 10 -25 24 -36 14 -11 25 -28 25 -38 0 -15 2 -16
13 -5 10 10 6 18 -19 39 -17 15 -34 34 -37 42 -4 12 -6 12 -6 -2z"/>
<path d="M3356 1538 c-5 -16 -5 -40 0 -55 l8 -28 4 28 c2 15 2 39 0 55 l-4 27
-8 -27z"/>
<path d="M3429 1514 c2 -31 6 -42 12 -33 12 20 11 42 -4 62 -10 15 -12 10 -8
-29z"/>
<path d="M3676 1547 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M1975 1527 c-4 -10 -3 -29 4 -44 16 -35 20 -26 11 22 -5 31 -9 36
-15 22z"/>
<path d="M2425 1529 c-8 -12 2 -59 13 -59 4 0 6 16 4 35 -4 37 -7 41 -17 24z"/>
<path d="M3607 1527 c-6 -16 9 -75 17 -66 2 2 1 22 -3 44 -5 29 -9 35 -14 22z"/>
<path d="M2860 1508 c0 -22 47 -58 74 -57 15 0 12 4 -13 13 -18 6 -39 23 -47
36 -9 18 -13 20 -14 8z"/>
<path d="M2955 1510 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M1421 1474 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1643 1476 c1 -11 9 -21 17 -23 10 -3 11 -2 4 4 -6 4 -14 15 -17 23
-5 11 -6 9 -4 -4z"/>
<path d="M3745 1460 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
